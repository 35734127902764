import * as React from 'react';
import { lazy } from 'react';
import { Box, CircularProgress, IconButton, useTheme, useMediaQuery, Stack, Typography } from '@mui/material';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { SnackbarProvider } from 'notistack';

import { AppContextProvider } from './AppContext';
import CustomThemeProvider, { baseTheme } from './CustomTheme';

import Close from '@mui/icons-material/Close';

import NavBar from './components/NavBar';
import SecureStorageDashboard from './pages/SecureStorageDashboard';

const ResultTables = lazy(() => import('./pages/ResultTables'));
const Splitting = lazy(() => import('./components/secure_storage/Splitting'));
const Merging = lazy(() => import('./components/secure_storage/Merging'));
const PageNotFound = lazy(() => import('./pages/PageNotFound'));
const ImageMainTable = lazy(() => import('./components/secure_storage/ImageMainTable'));
const MergeResultWithETable = lazy(() => import('./components/secure_storage/MergeResultWithETable'));
const MergeResultWithoutETable = lazy(() => import('./components/secure_storage/MergeResultWithoutETable'));
const Visualizer = lazy(() => import('./components/secure_storage/Visualizer'));
const PublicSplitter = lazy(() => import('./components/secure_storage/PublicSplitter'));
const PublicMerger = lazy(() => import('./components/secure_storage/PublicMerger'));
const PublicMerger2 = lazy(() => import('./components/secure_storage/PublicMerger2'));

function App() {
    console.log('⟳ App Repaint!')
    const theme = useTheme()
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));

    // --------- Snackbar dismiss ----------
    const notistackRef = React.createRef();
    const snackbarDismissBtn = (key) => {
        return (
            <IconButton onClick={() => {
                notistackRef.current.closeSnackbar(key);
            }}
                sx={{ color: 'inherit' }}
            >
                <Close />
            </IconButton>
        );
    }
    const notistackProps = {
        maxSnack: 3,
        preventDuplicate: true,
        ref: notistackRef,
        action: snackbarDismissBtn,
        anchorOrigin: { vertical: 'bottom', horizontal: 'right' },
        classes: (smScreen ? { containerRoot: 'margin-bottomNav' } : {}),
    }

    return (
        <AppContextProvider>
            <CustomThemeProvider>
                <SnackbarProvider {...notistackProps}>
                    <Router basename={process.env.PUBLIC_URL}>
                        <NavBar />
                        <Box sx={{ minHeight: "calc(100vh - 180px)", flexGrow: 1, py: 4, px: { xs: 1, sm: 2, md: 3 } }} className='content-wrapper'>
                            <React.Suspense fallback={<SuspenseFallback />}>
                                <MainRoutes />
                            </React.Suspense>
                        </Box>
                        {/* {!smScreen && <Footer />} */}
                    </Router>
                </SnackbarProvider>
            </CustomThemeProvider>
        </AppContextProvider>
    );
}

function MainRoutes() {
    return (
        <Routes>
            <Route path="/SecureStorageDashboard" element={<ResultTables />} />
            <Route path="/ImageMainTable" element={<ImageMainTable />} />
            <Route path="/MergeResultWithETable" element={<MergeResultWithETable />} />
            <Route path="/MergeResultWithoutETable" element={<MergeResultWithoutETable />} />
            <Route path="/Results" element={<ResultTables />} />
            <Route path="/Splitting" element={<Splitting />} />
            <Route path="/Merging" element={<Merging />} />
            <Route path="/Visualizer" element={<Visualizer />} />
            <Route path="/PublicSplitter" element={<PublicSplitter />} />
            <Route path="/PublicMerger" element={<PublicMerger />} />
            <Route path="/PublicMerger2" element={<PublicMerger2 />} />
            <Route path="/" element={<SecureStorageDashboard />} />
            <Route path="*" element={<PageNotFound />} />
        </Routes>
    )
}

const SuspenseFallback = () => {

    return (
        <Stack spacing={2} sx={{ p: 4 }} justifyContent={"center"} alignItems={"center"}>
            <CircularProgress sx={{ color: "text.disabled" }} />
            <Typography variant='subtitle1' fontWeight={"light"}> Loading...</Typography>
        </Stack>
    )
}

export default App;
