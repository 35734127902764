import { useLocation, Link } from 'react-router-dom';
import { Button, Container, Stack, Grid, Typography } from '@mui/material';

import CallMergeIcon from '@mui/icons-material/CallMerge';
import CallSplitIcon from '@mui/icons-material/CallSplit';
import CommonWarning from '../components/secure_storage/CommonWarning';

const PATHS = [
    { color: "warning", caption: "Results", route: "/Results", icon: "" },
    { color: "info", caption: "Split", route: "/Splitting", icon: <CallSplitIcon sx={{ transform: "rotateZ(90deg)" }} /> },
    { color: "secondary", caption: "Merge", route: "/Merging", icon: <CallMergeIcon sx={{ transform: "rotateZ(90deg)" }} /> },
]

export default function SecureStorageDashboard() {

    return (
        <Container>
            <Stack spacing={4}>
                <Typography variant='h5' fontWeight={"bold"}>
                    Home
                </Typography>
                <CommonWarning />
                <Grid container gap={2} justifyContent={"center"} alignItems={"center"}>
                    {PATHS.map(x => (
                        <Grid key={x.caption} item xs={12} sm={12} md={3} lg={3}>
                            <Button color={x.color} variant='outlined' endIcon={x.icon} component={Link} to={x.route} sx={{ p: 2, borderRadius: 3, border: 3, ":hover": { border: 3 } }} fullWidth>
                                <Typography variant='subtitle1' fontWeight={"bold"} fontFamily={"JetBrains Mono"} align='left'>
                                    {x.caption}
                                </Typography>
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Stack>
        </Container>
    )
}