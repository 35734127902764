import { useState, useEffect, useContext } from 'react';
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { deepmerge } from "@mui/utils";
import { useMediaQuery } from '@mui/material';

import AppContext from './AppContext';

function CustomThemeProvider(props) {
    const smScreen = useMediaQuery(baseTheme.breakpoints.down('md'));
    const [appState, appStateDispatch] = useContext(AppContext)
    const [theme, setTheme] = useState(themeSelector);

    // select the appropriate theme
    function themeSelector() {
        if (appState.theme === 'light')
            return smScreen ? lightThemeSm : lightTheme
        else
            return smScreen ? darkThemeSm : darkTheme
    }

    // This effect actually change the theme when it is toggled 
    // and when the screen size changes to sm
    useEffect(() => {
        const newTheme = themeSelector()
        if (theme !== newTheme) {
            setTheme(newTheme)
        }
    }, [smScreen, appState])

    // set body background and color according to theme
    document.body.style.background = theme.palette.background.body
    document.body.style.color = theme.palette.text.primary

    return (
        <ThemeProvider theme={theme}>
            {props.children}
        </ThemeProvider>
    );
}

const baseTheme = createTheme()

export default CustomThemeProvider
export { baseTheme }

const lightTheme = createTheme(
    {
        _name: 'light',
        palette: {
            mode: 'light',
            background: {
                body: '#eeeeee',
            }
        },
    }
)

const darkTheme = createTheme(
    {
        _name: 'dark',
        palette: {
            mode: 'dark',
            primary: { main: '#61AFEF' },
            secondary: { main: '#C678DD' },
            text: { secondary: '#ABB2BF' },
            background: {
                body: '#282C34',
                paper: '#121212',
                default: '#272727',
            }
        },
    }
)

const lightThemeSm = createTheme(deepmerge(lightTheme,
    {
        _name: 'lightsm',
        palette: {
            background: {
                body: '#eeeeee',
            }
        },
    }
))

const darkThemeSm = createTheme(deepmerge(darkTheme,
    {
        _name: 'darksm',
        palette: {
            background: {
                body: '#272727',
            }
        },
    }
))