import * as React from 'react';
import { AppBar, Box, CardMedia, Divider, Menu, MenuItem, Stack, Toolbar, Tooltip } from '@mui/material';
import { Avatar, Collapse, Button, IconButton, Typography } from '@mui/material';
import { Link as HyperLink } from '@mui/material';
import { Link, useLocation } from "react-router-dom";
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import AppContext from '../AppContext';

import DarkModeOutlinedIcon from '@mui/icons-material/DarkModeOutlined';
import LightModeOutlinedIcon from '@mui/icons-material/LightModeOutlined';
import MenuIcon from '@mui/icons-material/Menu';
import SearchIcon from '@mui/icons-material/Search';
import CloseIcon from '@mui/icons-material/Close';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import StorageIcon from '@mui/icons-material/Storage';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import LinkIcon from '@mui/icons-material/Link';
import DevicesIcon from '@mui/icons-material/Devices';
import GppGoodIcon from '@mui/icons-material/GppGood';

import { ReactComponent as SSLogoSVG } from '../SSLogo.svg'
import SSLogo from '../SSLogo.png';

export default function NavBar() {
    const theme = useTheme()
    const [appState, appStateDispatch] = React.useContext(AppContext)
    const xsScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const smScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [openSearch, setOpenSearch] = React.useState(false)
    const [openDrawer, setOpenDrawer] = React.useState(false)
    const [currentTime, setCurrentTime] = React.useState(new Date())

    const location = useLocation()

    const toggleThemeClick = () => {
        appStateDispatch({ type: 'toggleTheme' })
        // closeSettingsMenu()
    }

    const toggleSearch = () => {
        setOpenSearch(!openSearch)
    }

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    const [openAbout, setOpenAbout] = React.useState(false)

    return (
        <Box className='navbar-wrapper' sx={{ mb: 8 }}>
            <AppBar position="fixed" color='transparent' className="navbar" sx={{ boxShadow: '0px 2px 7px 0px rgba(0,0,0,0.1)', backdropFilter: "blur(10px)" }}>
                <Toolbar sx={{ ...(false && { borderBottom: 1, borderColor: "text.disabled" }) }}>
                    <MainLogo />
                    <Box sx={{ flexGrow: .125 }} />

                    {!smScreen && <DesktopNavMenu />}

                    <Box sx={{ flexGrow: 1 }} />

                    <Stack direction={"row"} spacing={0.5}>
                        <Tooltip title="theme"><IconButton color={({ 'dark': undefined, 'light': "warning" }[theme.palette.mode])} onClick={toggleThemeClick}>{({ 'dark': <DarkModeOutlinedIcon />, 'light': <LightModeOutlinedIcon /> }[theme.palette.mode])}</IconButton></Tooltip>
                        {/* <Tooltip title="settings"><IconButton onClick={handleClick} disabled={open}><SettingsRoundedIcon /></IconButton></Tooltip> */}
                    </Stack>
                    <Menu
                        id="app-settings"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'right',
                        }}
                    >
                        <MenuItem onClick={() => { handleClose(); setOpenAbout(true) }} sx={{ minWidth: 20 }} >
                            <Stack direction={"row"} spacing={1} justifyContent={"center"} alignItems={"center"}>
                                <InfoOutlinedIcon fontSize='1rem' color='info' />
                                <Typography variant='subtitle2' fontWeight={"light"}>About</Typography>
                            </Stack>
                        </MenuItem>
                    </Menu>

                    {
                        smScreen &&
                        <IconButton onClick={() => { setOpenDrawer(!openDrawer) }}>
                            {openDrawer ? <CloseIcon /> : <MenuIcon />}
                        </IconButton>
                    }
                </Toolbar>
                {smScreen && <MobileNavMenu open={openDrawer} onClose={() => { setOpenDrawer(false) }} />}
                <Divider />
            </AppBar>
        </Box>
    )
}

function MainLogo() {
    return (
        <Box color={"inherit"}
            sx={{ display: 'flex', flexDirection: 'row', justifyContent: "center", alignItems: "center", flexShrink: 0, textDecoration: 'none' }}
            component={Link} to="/"
        >
            <Tooltip title="secure storage">
                <Avatar variant='rounded' sx={{ mr: 1 }} src={SSLogo} />
                {/* <Box sx={{ border: 2, height: 35, width: 35, position: "relative", borderColor: "divider" }}>
                    <Box sx={{ position: "absolute", top: 2, left: 0, height: 35, width: 35, justifyContent: "center", alignItems: "center" }}>
                        <Typography align='center' variant='body2' color={"success.main"} fontFamily={"JetBrains Mono"} fontWeight={"bold"}>S S</Typography>
                        <Typography align='center' variant='body2' color={"success.main"} fontFamily={"JetBrains Mono"} fontWeight={"bold"}>---</Typography>
                    </Box>
                </Box> */}
            </Tooltip>
        </Box>
    )
}

function DesktopNavMenu() {
    return (
        <NavMenu sx={{ mb: -0.5, ml: 2 }}>
            <NavItem label="HOME" to="/" exact={true} />
            <NavItem label="RESULTS" to="/Results" exact={true} subMenu={
                <>
                    <NavSubItem label="Image main table" to="/ImageMainTable" />
                    <NavSubItem label="Merge result with essential share table" to="/MergeResultWithETable" />
                    <NavSubItem label="Merge result without essential share table" to="/MergeResultWithoutETable" />
                </>
            } />
            <NavItem label="SIMPLE SPLIT" to="/Splitting" exact={true} />
            <NavItem label="SIMPLE MERGE" to="/Merging" exact={true} />
            {/* <NavItem label="VISUALIZE" to="/Visualizer" exact={true} /> */}
            <NavItem label="SPLIT TESTER" to="/PublicSplitter" exact={true} />
            <NavItem label="MERGE TESTER" to="/PublicMerger" exact={true} />
            <NavItem label="MERGE TESTER 2" to="/PublicMerger2" exact={true} />
            {/* <NavItem label="Transactions" to="/transaction"
                subMenu={
                    <>
                        <NavSubItem label="NFT" to="/transaction/nft" />
                        <NavSubItem label="KNCT" to="/transaction/token" />
                        <NavSubItem label="Mint" to="/transaction/mint" />
                    </>
                }
            /> */}
            {/* <NavItem label="Users" to="/user" /> */}
            {/* <NavItem label="Tokens" to="/token" /> */}
            {/* <NavItem label="More"
                subMenu={
                    <>
                        <NavSubItem label="Calendar" to="/calendar" />
                        <NavSubItem label="Map" to="/map" />
                    </>
                }
            /> */}
        </NavMenu>
    )
}

function MobileNavMenu({ open, onClose }) {
    const location = useLocation()

    React.useEffect(() => {
        onClose()
    }, [location])

    return (
        <Collapse in={open} sx={{ width: '100%' }}>
            <Divider />
            <Box sx={{ width: '100%', my: 2 }}>
                <MNavItem label="HOME" to="/" exact={true} />
                <MNavItem label="RESULTS" to="/Results" exact={true} subMenu={
                    <>
                        <MNavSubItem label="Image main table" to="/ImageMainTable" />
                        <MNavSubItem label="Merge result with essential share table" to="/MergeResultWithETable" />
                        <MNavSubItem label="Merge result without essential share table" to="/MergeResultWithoutETable" />
                    </>
                } />
                <MNavItem label="SIMPLE SPLIT" to="/Splitting" exact={true} />
                <MNavItem label="SIMPLE MERGE" to="/Merging" exact={true} />
                {/* <MNavItem label="VISUALIZE" to="/Visualizer" exact={true} /> */}
                <MNavItem label="SPLIT TESTER" to="/PublicSplitter" exact={true} />
                <MNavItem label="MERGE TESTER" to="/PublicMerger" exact={true} />
                <MNavItem label="MERGE TESTER 2" to="/PublicMerger2" exact={true} />
                {/* <MNavItem label="Transactions" to="/transaction"
                    subMenu={
                        <>
                            <MNavSubItem label="NFT" to="/transaction/nft" />
                            <MNavSubItem label="KNCT" to="/transaction/token" />
                            <MNavSubItem label="Mint" to="/transaction/mint" />
                        </>
                    }
                /> */}
                {/* <MNavItem label="Users" to="/user" /> */}
                {/* <MNavItem label="Tokens" to="/token" /> */}
                {/* <MNavItem label="More"
                    subMenu={
                        <>
                            <MNavSubItem label="Calendar" to="/calendar" />
                            <MNavSubItem label="Map" to="/map" />
                        </>
                    }
                /> */}
            </Box>
        </Collapse>
    );
}

function NavMenu({ children, ...rProps }) {
    const sx = { display: 'flex' }
    rProps.sx = ('sx' in rProps) ? { ...(rProps.sx), ...sx } : sx

    return (
        <Box {...rProps}>
            {children}
        </Box>
    );
}

function NavItem({ label, to = "", subMenu, exact = false }) {
    const location = useLocation()
    const active = to ? pathMatch(location.pathname, to, exact) : false

    const [menuOpen, setMenuOpen] = React.useState(false)
    const [menuWidth, setMenuWidth] = React.useState(100)
    const boxRef = React.useRef(null)

    function openSubMenu(e) {
        e.preventDefault();
        setMenuOpen(true);
    }
    React.useEffect(() => {
        if (boxRef.current && menuWidth !== boxRef.current.offsetWidth)
            setMenuWidth(boxRef.current.offsetWidth)
    })

    return (
        <Box ref={boxRef}>
            <Button disableRipple={true} {...(to ? { component: Link, to: to } : { component: "div", onClick: openSubMenu })}
                sx={{ borderRadius: 10, ...(!subMenu ? { px: 2 } : { pl: 2, pr: 1 }), textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
            >
                <Typography variant='body2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
                {
                    subMenu &&
                    <IconButton onClick={openSubMenu}
                        sx={{ px: 0.5, my: -0.5, py: 0.5, ml: 0.5, mr: -0.5, minWidth: 0, color: (active ? 'primary.main' : 'text.secondary') }}
                    >
                        <KeyboardArrowDownIcon fontSize='small' />
                    </IconButton>
                }
            </Button>
            {
                subMenu &&
                <Menu anchorEl={boxRef.current} open={menuOpen} onClose={() => { setMenuOpen(false) }}
                    sx={{ '& .MuiMenuItem-root': { p: 0 } }}
                    MenuListProps={{ sx: { minWidth: menuWidth + 'px' } }}
                    PopoverClasses={{ className: 'shadow-sm' }}
                >
                    {
                        subMenu.props.children.map((item, index) => {
                            return <MenuItem key={index} onClick={() => { setMenuOpen(false) }}>{item}</MenuItem>
                        })
                    }
                </Menu>
            }
        </Box>
    )
}

function NavSubItem({ label, to }) {
    const location = useLocation()
    const active = pathMatch(location.pathname, to)

    return (
        <Button disableRipple={true} component={Link} to={to} fullWidth
            sx={{ justifyContent: 'start', px: 2, textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
        >
            <Typography variant='body2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
        </Button>
    );
}

function MNavItem({ label, to = "", subMenu, exact = false }) {
    const location = useLocation()
    const active = to ? pathMatch(location.pathname, to, exact) : false

    const [menuOpen, setMenuOpen] = React.useState(false)

    function openSubMenu(e) {
        e.preventDefault();
        setMenuOpen(!menuOpen);
    }

    return (
        <Box>
            <Button {...(to ? { component: Link, to: to } : { component: "div", onClick: openSubMenu })} fullWidth disableRipple
                sx={{ pl: 2, justifyContent: 'start', textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
            >
                <Typography variant='body2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
                {
                    subMenu &&
                    <Button onClick={openSubMenu}
                        sx={{ ml: 'auto', px: 1, my: -0.5, py: 0.5, minWidth: 0, color: (active ? 'primary.main' : 'text.secondary') }}
                    >
                        {menuOpen ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </Button>
                }
            </Button>
            {
                subMenu &&
                <Collapse in={menuOpen} sx={{ width: '100%' }}>
                    <Box sx={{ width: '100%' }}>
                        {subMenu}
                    </Box>
                </Collapse>
            }
        </Box>
    )
}

function MNavSubItem({ label, to }) {
    const location = useLocation()
    const active = pathMatch(location.pathname, to)

    return (
        <Button component={Link} to={to} fullWidth disableRipple
            sx={{ justifyContent: 'start', px: 2, pl: 4, textTransform: 'capitalize', fontWeight: 'normal', color: (active ? 'primary.main' : 'text.secondary') }}
        >
            <Typography variant='body2' fontWeight={active ? "bold" : undefined}>{label}</Typography>
        </Button>
    );
}


function pathMatch(srcPath, matchPath, exact = false) {
    if (exact && srcPath.length !== matchPath.length)
        return false
    return Boolean(srcPath.slice(0, matchPath.length).match(matchPath))
}